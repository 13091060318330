@import url(./App.css);
@import url(./NewUpdate.css);

.userRegisterContainer {
  height: 100vh;
  background-image: url(../src/asset/loginNewBackImage.jpeg);
  /* background-image: url("../src/asset/loginBackAround.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.userRegisterInnerContainer {
  height: 100vh;
  width: 100%;
  /* background-color: rgba(39, 49, 95, 0.5); */
  /* backdrop-filter: blur(8px); */
}
.userRegister_registerLogo {
  width: 100%;
  height: calc(85vh - 58vh);
}
.userRegister_registerMainContainer {
  /* height: 85vh;
  width: 70%; */
  background-color: #fff;
}
.userRegister_registerSection {
  width: 100%;
}
.userRegister_registerForm {
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 0px 50px;
  width: 50%;
  height: calc(85vh - 27vh);
}
.userRegister_registerRightImgDiv {
  width: 50%;
  /* background-color: aqua; */
}
.userRegister_registerRightImg {
  width: 100%;
  height: calc(85vh - 27vh);
}
.loginHatImage {
  height: calc(100vh - 60vh);
}
@keyframes hatLeftToRight {
  from {
    left: -1000px; /* Start position (off-screen to the left) */
  }
  to {
    left: 0px; /* Final position */
  }
}
.hatMove {
  animation: hatLeftToRight 1.5s ease-in-out forwards;
}
.loginHatImage {
  height: calc(60vh - 25vh);
  position: relative;
  bottom: 78px;
  top: 0px;
  left: -200px; /* Initial position for the animation */
  z-index: 100;
  animation: hatLeftToRight 1.5s ease-in-out forwards; /* Apply animation */
}
@keyframes moveBottomToTop {
  from {
    bottom: -1000px; /* Start below the visible area */
  }
  to {
    bottom: 78px; /* Final position */
  }
}
@keyframes padImageBottomToTop {
  from {
    bottom: -500px; /* Start below the visible area */
  }
  /* to {
    bottom: 78px; Final position
  } */
}
.loginBinocularImage {
  height: calc(60vh - 25vh);
  position: relative;
  bottom: -200px; /* Start position for animation */
  right: 10px;
  z-index: 100;
  animation: moveBottomToTop 1.5s ease-in-out forwards; /* Apply animation */
}
@keyframes whiteSheetTopToBottom {
  from {
    top: -1000px; /* Start above the visible area */
  }
  to {
    top: 30px; /* Final position */
  }
}

.loginWhiteSheet {
  height: 300px;
  transform: rotate(15deg);
  position: relative;
  top: 100px; /* Initial position for the animation */
  /* left: 210px; */
  animation: whiteSheetTopToBottom 1.5s ease-in-out forwards; /* Apply animation */
}
.loginNotPadImage {
  height: calc(45vh - 20vh);
  height: 600px !important;
  position: relative;
  bottom: 380px;
  /* left: 100px; */
  animation: padImageBottomToTop 1.5s ease-in-out forwards;
}
.notPadImage {
  /* transform: rotate(10deg) */
}
/* .loginNewBackImage{
  background-image: url(../src/asset/loginNewBackImage.jpeg);
} */

.modalSubmitBtn {
  height: 40px;
  width: 70px;
  border: none;
  border-radius: 6px;
  background-color: #0c3d74;
}
.registerInputCss {
  /* background-color: blue; */
  width: 100%;
  padding: 0px 8px;
  border: none;
  outline: none;
  border-radius: 10px;
}
.userRegisterInputCss {
  /* background-color: blue; */
  height: 40px;
  width: 100%;
  padding: 0px 8px;
  border: none;
  outline: none;
}
.registerInputCss {
  /* background-color: blue; */
  height: 40px;
  width: 100%;
  padding: 0px 8px;
  border: none;
  outline: none;
}
.userRegisterInputs {
  height: 60px;
  width: 100%;
  padding: 8px 6px;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.registerInputDivCss {
  height: 60px;
  width: 40%;
  padding: 8px 6px;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
}
.--userRegisterInputs {
  padding: 8px 6px;
  border: 1px solid black;
  border-radius: 50px;
  margin-bottom: 10px;
}
.userRegisterInputLogo {
  padding: 6px;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: grey;
}

.userRegisterBtnContainer {
  padding: 6px 6px;
  /* border: 1px solid black; */
  background-color: #0c3d74;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.userRegisterBtn {
  outline: none;
  background-color: #0c3d74;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
/* userLogin start */
.userLoginNavigate:hover {
  cursor: pointer;
  color: blue;
}
/* userLogin end */

/* Table styling */
.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table_fileUpload {
  width: 100%;
  text-align: center;
  overflow: scroll;
  padding: 0px 20px;
  padding-top: 10px;
  scrollbar-width: none;
  /* scrollbar-color: #adadad var(--themeColor); */
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: unset;
  scroll-snap-type: y mandatory;
}

/* Pagination styling */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin: 20px 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

/* sidebar css start */
.sideBarMenuItem {
  /* color: #fff; */
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.sideBarMenuItem:hover {
}
/* .css-1tqrhto >.ps-menu-button:hover{
  background-color: #002147;
  color: #fff;
} */
/* .sidebarSubMenu{
  background-color: #002147;
  color: #fff;
}
.sidebarSubMenu:hover{
  background-color: #0c3d74;
  color: #fff;
} */
/* .css-16jesut >.ps-menu-button:hover{
  background-color: #0c3d74;
} */
/* .sidebarList {
  background-color: #002147;

}
.sidebarList:hover {
  background-color: #d9d9d9;
} */
.sidebarListName {
  width: 100%;
  font-size: 14px !important;
  font-weight: 700 !important;
  /* color: #d9d9d9; */
}

.sideBarMenuItem {
  background-color: #d9d9d9;
}

/* .nav-link:hover {
  color: #002147 !important;
  background-color: #002147 !important;
} */

/* sidebar css end */

/* file upload Css start */
.fileNameHeader {
  width: 100px !important;
}
.fileNewUpload {
  /* height: 60px; */
  width: 18%;
  background-color: #002147;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 10px;
  border: 1px solid #002147;
}
.fileNewUpload:hover {
  color: #002147;
  border: 1px solid #002147;
  background-color: #d9d9d9;
}
/* file upload Css end */
/* Table Start */
.product_table_container_sticky {
  width: 100% !important;
  height: calc(100vh - 130px);
  scrollbar-width: thin;
  border-radius: 15px 15px 15px 15px;
  /* padding-top: 10px; */
  box-shadow: 0px 2px 10px 0px #1400ff40;
  background-color: #ffffff !important;
  overflow-x: auto !important;
}

.register-table-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
}
.filter-table-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 155px);
  /* border: 1px solid red; */
}
.filter-table {
  width: 100% !important;
  height: calc(100vh - 170px);
  scrollbar-width: thin;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 2px 10px 0px #1400ff40;
  background-color: #ffffff !important;
  overflow-x: auto !important;
  /* border: 1px solid red; */
}
.register-form-block {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.register-form-container {
  width: 100%;
  height: calc(100vh - 100px);
  margin: 5px 0px;
  /* overflow-y: auto; */
  scrollbar-width: thin;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fileUpload_container_sticky {
  width: 100% !important;
  height: calc(100vh - 190px);
  /* scrollbar-width: thin; */
  /* margin: 10px; */
  border-radius: 15px 15px 15px 15px;
  /* padding-top: 20px; */
  box-shadow: 0px 2px 10px 0px #1400ff40;
  background-color: #ffffff !important;
  overflow-x: auto !important;
  scrollbar-width: none;
  /* position: sticky !important;
  top: 0 !important;
  left: 0 !important; */
}

.ss-1jxw05q-MuiTableCell-root {
  background-color: #002147 !important;
}
.sticky-header {
  position: sticky;
  top: 0; /* Keeps it at the top of the container */
  z-index: 1; /* Ensures the header is above the body rows */
  background-color: #fff; /* Optional: keeps header background solid */
}
.product_table_heading {
  font-family: "Times New Roman", Times, serif;
  background-color: #002147 !important;
  /* width: 100% !important; */
  font-size: 14px !important;
  font-weight: 600 !important;
  text-align: left !important;
  color: #ffffff !important;
  padding: 5px 10px !important;
  align-items: center;


}
.product_table_heading_small {
  font-family: "Times New Roman", Times, serif;
  background-color: #002147 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-align: left !important;
  color: #ffffff !important;
  padding: 5px 10px !important;
  width: 100px !important;
  word-wrap: break-word;

}
.product_table_data {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px !important;
  font-weight: 200 !important;
  text-align: left !important;
  background-color: #ffffff;
  padding: 10px 15px !important;

  /* Auto-adjust width based on content */
  white-space: normal !important; /* Allows wrapping */
  word-wrap: break-word !important; /* Breaks long words if needed */
  overflow-wrap: break-word !important; /* Ensures consistent breaking */
  max-width: 100% !important; /* Prevents overflow beyond container */
}
.product_table_data_big {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px !important;
  font-weight: 200 !important;
  text-align: left !important;
  background-color: #ffffff;
  padding: 10px 15px !important;
  width: 250px;
}
.product_table_data_small {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px !important;
  font-weight: 200 !important;
  text-align: left !important;
  background-color: #ffffff;
  padding: 10px 10px !important;
  width: 100px !important;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4 !important;
  vertical-align: middle;
}
.product_table_heading_first_sticky {
  position: sticky !important;
  left: 0px !important;
  top: 0px !important;
}
.product_table_heading_second_sticky {
  position: sticky !important;
  left: 140px !important;
  top: 0px !important;
}
.product_table_heading_third_sticky {
  position: sticky !important;
  left: 280px !important;
  top: 0px !important;
}
.product_table_heading_fourth_sticky {
  position: sticky !important;
  left: 420px !important;
  top: 0px !important;
}

.viewRegister_table_data {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px !important;
  font-weight: 200 !important;
  text-align: start !important;
  background-color: #ffffff;
  line-height: normal !important;
  height: 60px !important ;
  width: 20% !important;
}
.product_table_data_first_sticky {
  position: sticky !important;
  left: 0px !important;
  top: 0px;
}
.product_table_data_second_sticky {
  position: sticky !important;
  left: 140px !important;
  top: 0px;
}
.product_table_data_third_sticky {
  position: sticky !important;
  left: 280px !important;
  top: 0px;
}
.product_table_data_fourth_sticky {
  position: sticky !important;
  left: 420px !important;
  top: 0px;
}
/* Table END */
.bg-white_loginform {
  background-color: #fff;
  padding: 20px;
  width: 80%;
  height: 50%;
  border-radius: 15px;
  border: 1px solid rgba(102, 102, 102, 0.25);
}
.filter-content {
  font-size: 14px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 400;
}
.filter-button {
  border: none;
  height: 20px !important;
  width: 30px !important;
  line-height: normal;
  border-radius: 1px;
  text-align: center;
}
.sideBarMenuItem {
  color: black; /* Default color */
  /* padding: 10px; */
}

.active-menu {
  background-color: #002147 !important; /* Highlight active item */
  color: white !important;
  /* font-weight: bold; */
}
.active-menu :hover {
  background-color: #002147 !important; /* Highlight active item */
  color: white !important;
  /* font-weight: bold; */
}
.addAlt_text{
  font-size: 22px;
  text-align: center;
}
.addAltContainer{
  height: 140px;
  padding: 20px 0px;
}
.addAltBtn{
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 0px;
}
.save-btn{
  background-color: lightgreen;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}
.dont-save-btn{
  background-color: rgb(196, 78, 78);
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}


