.mainContainerDiv {
  width: 100%;
  height: 100vh;
  /* background-color: aqua; */
  padding: 0px 15px;
  overflow: hidden;
  /* border: 1px solid red; */
}
.visitorEntryHeader {
  height: calc(100vh - 93vh);
  font-size: 30px;
  font-weight: 700;
  /* padding: 10px 0px; */
  text-align: center;
  margin: 10px 0px;
  /* color: #002147; */
  /* background-color: greenyellow ; */
}
.visitorEntryFormInput {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 18px;
}
.visitorEntryInputContainer {
  width: 18%;
  height: 80px;
  /* margin: 10px 20px; */
}
.visitorEntryDoubleInput {
  width: 18%;
  height: 80px;
}
.visitorInfoLabelContainer {
  font-size: 18px;
  font-weight: 400;
}
.visiterInfoCss {
  width: 100%;
  height: 40px;
  border: 1px solid gray;
  border-radius: 6px;
  outline: none;
}
.visitorInfoMobile {
  width: 18% !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.visitorInfoShowBtn {
  font-size: 14px;
  font-weight: 500;
  /* color:#fff; */
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: #07458d;
  margin: 25px 0px;
}
.visitorInfoSaveBtn {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: #002147;
  /* margin: 10px 0px; */
  cursor: pointer;
}
.visitorInfoPopupSave {
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: #002147;
  margin: 10px 0px;
  cursor: pointer;
}
.ClientInfoAddVisitorBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: #002147;
  /* margin: 1px 0px; */
  align-content: center;
  cursor: pointer;
  height: 30px;
}
.headerContainer {
  width: 100%;
  background-color: #3c30bb;
  /* background-color: #5729CE; */
  color: #fff;
  /* padding: 8px; */
}
.css-2avex9-MuiAutocomplete-root {
  height: 40px;
}
.MuiOutlinedInput-root {
  height: 40px;
}
