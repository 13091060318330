.userRegisterContainer {
  height: 100vh;
  /* background-color: blueviolet; */
  /* background-image: url("../src/asset/loginBackAround.png"); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}

.add-user-btn-block {
  border-radius: 5px;
  background: #002147;
  box-shadow: 2px 2px 4px 0px #543310;
  display: flex;
  width: 100%;
  padding: 3px 5px;
  cursor: pointer;
}
.add-user-btn-block-delete {
  border-radius: 5px;
  /* background:#002147; */
  /* box-shadow: 2px 2px 4px 0px #543310; */
  display: flex;
  width: px;
  /* padding: 0px 5px; */
  cursor: pointer;
  /* height: 30px; */
  margin-bottom: 20px;
}
.new-user-btn {
  width: 100%;
  padding: 5px 5px;
  background: #002147;
  background: none;
    font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  height: 30px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
}
.new-client-btn {
  width: 50%;
  padding: 5px 5px;
  background: #002147;
  background: none;
    font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
}
.delete-btn {
  width: 12%;
  background: none;
  border: none;
}
.new-register-btn {
  width: 21%;
  padding: 5px 5px !important;
  background: #002147 !important;
    font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: 600;
  /* line-height: 14.52px; */
  height: 45px;
  text-align: center;
  color: #ffff;
  border: none;
  outline: none;
  border-radius: 5px;
}
.--client-register {
  width: 25%;
  height: 40px;
}
.new-register-btn:hover {
  background-color: #001429 !important;
}
.new-user-btn_option {
  background-color: #002147;
}
.search-block {
  border: 0.5px solid rgba(56, 99, 124, 0.3);
  width: 290px;
  border-radius: 5px;
  font-size: 14px;
}
.search-block-client-view {
  border: 0.5px solid rgba(56, 99, 124, 0.3);
  width: 45%;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 42px;
}
.--filter-option {
  width: 240px;
}
.search-block-dropdown {
  border: 0.5px solid rgba(56, 99, 124, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 290px;
  border-radius: 9px;
  /* height: 50px; */
}
.css-t3ipsp-control {
  min-height: 40px !important;
}
.css-13cymwt-control {
  min-height: 40px !important;
}
.removeIcon {
  cursor: pointer;
  color: black;
}
.removeIcon:hover {
  background: rgba(255, 0, 0, 0.342);
}

.table-search {
  border: none;
  outline: none;
  border-radius: 9px;
  width: 100%;
  /* height: 40px; */
}
.image_icon {
  width: 20px;
  height: 20px;
}
.table-wrapper {
  scrollbar-width: none;
  /* scrollbar-color: #adadad var(--themeColor); */
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: unset;
  scroll-snap-type: y mandatory;
  height: calc(100vh - 32%);
  /* overflow: hidden; */
}
.table-wrapper_fileUpload {
  scrollbar-width: none;
  /* scrollbar-color: #adadad var(--themeColor); */
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: unset;
  scroll-snap-type: y mandatory;
  height: calc(100vh - 38%);
  /* overflow: hidden; */
}
/* .table-wrapper {
  width: 100%;
  overflow-x: auto; 
} */
.table_label_header {
  /* SELECT */

  /* width: 47px;
height: 15px; */

    font-family: "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 15px;
  margin: 0px;

  color: #ffffff;
}
.pagination-wrapper {
  display: flex;
  justify-content: space-between; /* This will ensure space between previous and next */
  padding: 10px 0;
  width: 95%;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin: 0 auto;
  flex-grow: 1;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.pagination .active {
  background-color: #007bff;
  color: white;
}

.previous,
.next {
  padding: 8px 12px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.previous:hover,
.next:hover {
  background-color: #d0d0d0;
}
.table_ss {
  width: 190%;
  overflow: scroll;
  padding: 0px 20px;
  padding-top: 10px;
  scrollbar-width: none;
  /* scrollbar-color: #adadad var(--themeColor); */
  /* scroll-padding-inline-start: auto; */
  scroll-snap-align: start;
  scroll-behavior: unset;
  scroll-snap-type: y mandatory;
}
.table_form_page {
  /* margin-left: 30px; */
  /* background-color: rgb(229, 229, 229); */
  height: 100%;
}
/* bb *
/
/* From Uiverse.io by Nawsome */
.banter-loader {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 72px;
  height: 72px;
  /* margin-left: -36px; */
  /* margin-top: -36px; */
}

.banter-loader__box {
  float: left;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.banter-loader__box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #002147;
}

.banter-loader__box:nth-child(3n) {
  margin-right: 0;
  margin-bottom: 6px;
}

.banter-loader__box:nth-child(1):before,
.banter-loader__box:nth-child(4):before {
  margin-left: 26px;
}

.banter-loader__box:nth-child(3):before {
  margin-top: 52px;
}

.banter-loader__box:last-child {
  margin-bottom: 0;
}

@keyframes moveBox-1 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(0px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 0px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(1) {
  animation: moveBox-1 4s infinite;
}

@keyframes moveBox-2 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 26px);
  }

  81.8181818182% {
    transform: translate(0px, 26px);
  }

  90.9090909091% {
    transform: translate(0px, 26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(2) {
  animation: moveBox-2 4s infinite;
}

@keyframes moveBox-3 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(-26px, 0);
  }

  54.5454545455% {
    transform: translate(-26px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(3) {
  animation: moveBox-3 4s infinite;
}

@keyframes moveBox-4 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, 0px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(4) {
  animation: moveBox-4 4s infinite;
}

@keyframes moveBox-5 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(0, 0);
  }

  27.2727272727% {
    transform: translate(0, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 0);
  }

  54.5454545455% {
    transform: translate(26px, 0);
  }

  63.6363636364% {
    transform: translate(26px, 0);
  }

  72.7272727273% {
    transform: translate(26px, 0);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(5) {
  animation: moveBox-5 4s infinite;
}

@keyframes moveBox-6 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(0px, 0);
  }

  72.7272727273% {
    transform: translate(0px, 26px);
  }

  81.8181818182% {
    transform: translate(-26px, 26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(6) {
  animation: moveBox-6 4s infinite;
}

@keyframes moveBox-7 {
  9.0909090909% {
    transform: translate(26px, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(26px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(7) {
  animation: moveBox-7 4s infinite;
}

@keyframes moveBox-8 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(8) {
  animation: moveBox-8 4s infinite;
}

@keyframes moveBox-9 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-52px, 0);
  }

  90.9090909091% {
    transform: translate(-26px, 0);
  }

  100% {
    transform: translate(0px, 0);
  }
}

.banter-loader__box:nth-child(9) {
  animation: moveBox-9 4s infinite;
}

/* visitor add start */
.visitorAdd_mainContainer {
  height: 100%;
  width: 100%;
  background: #d4d4d4;
  /* padding: 10px 16px; */
}
.visitorAddHeader {
  background-color: #002147;
  color: #fff;
  text-align: center;
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
}
.visitorAddCss {
  height: 90px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 6px;
  outline: none;
}
.visitorAddAmtDetails {
  height: 50px;
  background-color: #fff;
  /* display: flex;
  align-items: center; */
  text-align: center;
  padding: 8px;
  /* border-radius: 5px; */
  border: 3px solid gray;
  outline: none;
  font-size: 14px;
  /* font-weight: 700; */
}

/* visitor add end */
.nav_active {
  background-color: #002147 !important;
  color: #fff !important;
}
.header_section_nav {
  padding-left: 12px !important;
}
.clientViewMainSection {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visitor-main-container {
  width: 100%;
  height: calc(100vh - 5px) !important;
  /* border: 1px solid red; */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.client-view-head {
  color: #002147;
  font-size: 24px;
  font-weight: 700;
  /* font-family:; */
    font-family: "Times New Roman", Times, serif;
}
.addressAlertContainer{
  z-index: 9999 !important;
}
.error{
  border-color: red;
}